import React from 'react';
import { Form } from 'react-bootstrap';

const CustomInput =(props)=>{
    console.log(props.className);
    return(
        <Form>
            <Form.Group className='mb-3'></Form.Group>
            <Form.Control 
                type={'text'} 
                placeholder={props.placeholder !=null ? props.placeholder : ''}
                style={props.style}
                as ={props.asType ? props.asType : 'input'}
                rows={props.rows}
                />
        </Form>
    )
}
export default CustomInput