import React, { useState, useEffect, useRef } from 'react';
import { Button, Carousel, Col, Container, Row } from 'react-bootstrap';
import styles from '../styles/HomePage.module.css'
import background from '../images/contact_us_bg.png'
import CustomInput from '../components/CustomInput';

const ContactUs = () => {
    return (
        <Row style={{
            height: '90vh',
        }}>
            <Col xs={5} md={6}>
                <div style={{
                    height: '100%',
                    position: 'relative'
                }}>
                    <div style={{
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '70%',
                        height: '100%',
                        backgroundPosition: 'bottom right',
                    }}>
                        <span className={styles.absolute_title}>US</span>
                        <p className={styles.rotated_title} >contact</p>
                    </div>
                </div>
            </Col>
            <Col xs={1}></Col>
            <Col xs={5} className="d-flex align-items-center justify-content-center">
                <Row>
                    <Col xs={10}>
                        <CustomInput
                            placeholder='FULL NAME'
                            style={{
                                padding: '15px',
                                border: '1px solid #017EFF',
                                color: '#017EFF'
                            }}
                        />
                    </Col>
                    <Col xs={10}>
                        <CustomInput
                            placeholder='EMAIL'
                            style={{
                                padding: '15px',
                                border: '1px solid #017EFF',
                                color: '#017EFF'
                            }}
                        />
                    </Col>
                    <Col xs={10}>
                        <CustomInput
                            placeholder='SUBJECT'
                            style={{
                                padding: '15px',
                                border: '1px solid #017EFF',
                                color: '#017EFF'
                            }}
                        />
                    </Col>
                    <Col xs={10}>
                        <CustomInput
                            placeholder='CONTENT'
                            style={{
                                padding: '15px',
                                border: '1px solid #017EFF',
                                color: '#017EFF'
                            }}
                            asType={'textarea'}
                            rows={6}
                        />
                    </Col>
                    <Col xs={10}>
                        <Button variant="primary" type="submit" 
                                    style={{
                                    width:'40%'
                                     ,padding:'10px'
                                     ,marginTop:'30px'
                                     ,float:'right'}}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default ContactUs