import React, { useState } from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import background from '../images/shifra_tech_background.png'
import image2 from '../images/image2.png'
import logo from '../images/Subtract.png'
import styles from '../styles/HomePage.module.css'
import carouselStyles from '../styles/Carousel.module.css'
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import Footer from './Footer';

const HomePage = () => {

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div style={{
            overflowX: 'hidden',
            // height:'400vh'
        }}>
            <Row>
                <Col xs={12}>
                    <Carousel
                        activeIndex={index}
                        indicators={false}
                        onSelect={handleSelect}
                        // fade
                        controls={false}
                    >
                        <Carousel.Item>
                            <div style={{
                                height: '100vh',
                                // position: 'relative'
                            }}>
                                {/* <img src={logo} style={{ position: 'absolute', left: '68px', top: '5%' }} /> */}
                                <div style={{
                                    backgroundImage: `url(${background})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    height: '100%',
                                    backgroundPosition: 'center'
                                }}>
                                    <div className={styles.textContainer}>
                                        <p className={styles.title}>
                                            WEB
                                        </p>
                                        <p className={styles.subTitle}>
                                            SOLUTIONS
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <Carousel.Caption>
                                <h3>First slide label</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption> */}
                        </Carousel.Item>
                        <Carousel.Item>
                            <div style={{
                                height: '100vh',
                                position: 'relative'
                            }}>
                                <div style={{
                                    backgroundImage: `url(${image2})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    height: '100%',
                                }}>
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                    <img src={logo} style={{ position: 'absolute', left: '68px', top: '5%' }} />
                    <div className={carouselStyles.paginationBox}
                        style={{ position: 'absolute', right: '20px', top: '40%', color: '#ffffff' }}>

                        <div className={carouselStyles.cube}>
                            <p onClick={() => handleSelect(0)}
                                style={{
                                    cursor: 'pointer',
                                    // fontSize: index == 0 ? '25px' : '16px',
                                    fontFamily: 'Brush Script MT,cursive'
                                }}
                                className={index == 0 ? carouselStyles.flip : carouselStyles.flipNormal}
                            >01</p>
                        </div>

                        <p onClick={() => handleSelect(1)}
                            style={{
                                cursor: 'pointer',
                                // fontSize: index == 1 ? '25px' : '16px',
                                fontFamily: 'Brush Script MT,cursive'
                            }}
                            className={index == 1 ? carouselStyles.flip : carouselStyles.flipNormal}
                        >02</p>
                    </div>
                </Col>
                <Col xs={12}>
                    <AboutUs />
                </Col>
                <Col xs={12}>
                    <ContactUs />
                </Col>
                <Col xs={12}>
                    <Footer />
                </Col>
            </Row>
            {/* <Row>
                
            </Row> */}
        </div>
    )
}
export default HomePage