import React, { useState, useEffect, useRef } from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import styles from '../styles/HomePage.module.css'
import background from '../images/about_us_bg.png'

const AboutUs = () => {
    const [isScrollingDown, setIsScrollingDown] = useState(false);
    const [scrollValue, setScrollValue] = useState(0);
    const [transformValue, setTransformValue] = useState(20);

    const componentRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const customStyle = {
        animate: {
            textAlign: 'center',
            transform: 'translateX(10%)',
            overflow: 'hidden',
            fontWeight: 'bold',
            minHeight: '50vh',
        },
        animate_scroll_down: {
            textAlign: 'center',
            transform: `translateX(${transformValue}%)`,
            fontSize: '14vw',
            overflow: 'hidden',
            fontWeight: 'bold',
            minHeight: '50vh',
            marginTop: '-2vh'
        },
        animate_scroll_up: {
            textAlign: 'center',
            transform: `translateX(${transformValue}%)`,
            fontSize: '14vw',
            overflow: 'hidden',
            fontWeight: 'bold',
            minHeight: '50vh',
            marginTop: '-2vh'
        },
        about: {
            textTransform: 'uppercase',
            WebkitTextStroke: '1px black',
            WebkitTextFillColor: 'transparent',
            position: 'relative',
        },
        span: {
            textTransform: 'uppercase',
            color: '#017EFF',
            position: 'absolute',
            top: '5vh',
            right: '14vw',
            fontSize: '17vw'
        },
        about_us_par: {
            display:'none',
        }
    }
    const [scroll, setScroll] = useState(customStyle.animate);

    const handleScroll = () => {
        if (window.pageYOffset > 0) {
            let total = parseFloat(scrollValue) - parseFloat(window.pageYOffset);
            if (total < 0) {
                setIsScrollingDown(true);
                // setTransformValue(transformValue - 0.3)
                setTransformValue(transformValue - 0.2)
                setScroll(customStyle.animate_scroll_down);
                // console.log(transformValue);
            } else if (window.pageYOffset >= 0 && window.pageYOffset <= 5) {
                setIsScrollingDown(false);
                setTransformValue(15)
                setScroll(customStyle.animate_scroll_up);
            }
            else {
                setIsScrollingDown(false);
                // setTransformValue(transformValue + 0.3)
                setTransformValue(transformValue + 0.2)
                setScroll(customStyle.animate_scroll_up);
                // console.log(transformValue);
            }
            setScrollValue(window.pageYOffset);
        } else {
            setIsScrollingDown(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [scrollValue]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIsVisible(entry.isIntersecting),
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.5 // The percentage of the component that needs to be visible to trigger the intersection observer
            }
        );

        if (componentRef.current) {
            observer.observe(componentRef.current);
        }
        return () => observer.unobserve(componentRef.current);
    }, [componentRef]);

    return (
        <div>
            <Row>
                <Col xs={12} style={{ position: 'relative' }}>
                    <div className={styles.movingContainer}>
                        <div style={scroll}>
                            <span style={customStyle.about}>About</span>
                            <span style={customStyle.span}>US</span>
                        </div>
                    </div>
                    <div style={{ position: 'relative' }} ref={componentRef}>
                        <div className={isVisible ? styles.about_us_paragraph : customStyle.about_us_par}>
                            Lorem ipsum dolor sit amet consectetur.
                            Ornare erat consectetur aliquet ornare adipiscing sed
                            aliquam tellus. Nullam odio quam imperdiet donec.
                            Lorem ipsum dolor sit amet consectetur.
                            Ornare erat consectetur aliquet ornare adipiscing sed
                            aliquam tellus. Nullam odio quam imperdiet donec.
                            Lorem ipsum dolor sit amet consectetur.
                            Ornare erat consectetur aliquet ornare adipiscing sed
                            aliquam tellus. Nullam odio quam imperdiet donec.
                            Lorem ipsum dolor sit amet consectetur.
                            Ornare erat consectetur aliquet ornare adipiscing sed
                            aliquam tellus. Nullam odio quam imperdiet donec.
                        </div>
                    </div>
                    <div>
                        <img src={background} alt="About Us" style={{
                            width: '100%', marginTop: '-20vh'
                        }} />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default AboutUs