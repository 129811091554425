import React, { useState } from 'react';
import styles from '../styles/HomePage.module.css'
import { Button, Col, Row } from 'react-bootstrap';
import CustomInput from '../components/CustomInput';
import logo from '../images/footer_logo.png'

const Footer = () => {
    return (
        <div className={styles.footer}>
            <Row>
                <Col xs={12} md={6}
                    className="d-flex align-items-center justify-content-center"
                    style={{ minHeight: '45vh' }}
                >
                    <Row style={{ marginLeft: '10rem' }}>
                        <Col xs={8}>
                            <p className={styles.footer_title}>stay up to date</p>
                        </Col>
                        <Col xs={8}>
                            <CustomInput
                                placeholder='EMAIL ADDRESS'
                                style={{
                                    padding: '15px',
                                    border: '1px solid #017EFF',
                                    color: '#017EFF',
                                    width: '100%'
                                }}
                            />
                        </Col>
                        <Col xs={8}>
                            <Button variant="primary" type="submit"
                                style={{
                                    width: '50%'
                                    , padding: '10px'
                                    , marginTop: '10px'
                                    , fontWeight: 'bold'
                                    , textTransform: 'uppercase'
                                }}>
                                Subscribe
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={1}
                className="d-flex align-items-center justify-content-center"
                 style={{ minHeight: '45vh' }}>
                    <Row className={styles.footer_menu}>
                        <Col xs={12}>
                            <p className={styles.footer_menu_item}>Home</p>
                        </Col>
                        
                        <Col xs={12}>
                            <p className={styles.footer_menu_item}>About us </p>
                        </Col>
                        
                        <Col xs={12}>
                            <p className={styles.footer_menu_item}>contact us</p>
                        </Col>
                    </Row>
                </Col>
                
                <Col xs={12} md={5} className="d-flex align-items-center justify-content-center">
                    <Row className={styles.footer_logo}>
                        <img src={logo} style={{
                            width:'100%'
                        }}/>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default Footer